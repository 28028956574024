import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { TermsConditionsComponent } from './components/shared/terms-conditions/terms-conditions.component';
import { PoliciesComponent } from './components/shared/policies/policies.component';
import { PortafolioComponent } from './components/portafolio/portafolio.component';


const routes: Routes = [
  { path: 'home', component: HomeComponent},
  { path: 'Portafolio', component: PortafolioComponent},
  { path: 'TerminosCondiciones', component: TermsConditionsComponent},
  { path: 'Politicas', component: PoliciesComponent},
  { path: '**',  redirectTo: 'home'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
