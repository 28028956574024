import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TestimoniosService {

  constructor(public http: HttpClient) { }

  getTestimonios() {
    // console.log('entro')
    return this.http.get('assets/data/testimonios.json');
  }

}
