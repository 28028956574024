import { Component, OnInit } from '@angular/core';
import { UtilitiesService } from 'src/app/services/utilities.service';
declare var $: any;
@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.css']
})
export class TermsConditionsComponent implements OnInit {

  constructor(public utilitiesService:UtilitiesService){
    utilitiesService.loading = true;
    setTimeout(function(){
      utilitiesService.loading = false;
    }, 1000);
}

  ngOnInit() {
    // $(function(){
    //   $('.ui-state-default').click(function () {
    //     $('html, body').animate({
    //        scrollTop: $(document).height()
    //     }, 'slow');
    //     return false;
    //   });
    // });

    this.scroll();
  }

  scroll() {
    $(function () {
          var dest = $(".ex-header").offset().top;
          $("html, body").animate({scrollTop: dest},600);

    });
  }

}
