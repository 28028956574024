import { Component, OnInit } from '@angular/core';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { Testimonio } from 'src/app/interfaces/testimonio';
import { TestimoniosService } from 'src/app/services/testimonios.service';
declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  public testimonios: Testimonio[] = [];

  constructor(
    public utilitiesService: UtilitiesService,
    public testimoniosService: TestimoniosService
    ) {
    this.scroll();
    this.getTestimonios();
    utilitiesService.loading = true;
    setTimeout( function () {
      utilitiesService.loading = false;
    }, 1500);

}

  ngOnInit() {


  }

  scroll() {
    var dest = $('.navbar-expand-md').offset().top;
    $('html, body').animate({scrollTop: dest},600);
  }

  getTestimonios(){
    this.testimoniosService.getTestimonios()
      .subscribe((response: Testimonio[]) => {
        this.testimonios = response;
        // console.log(this.testimonios);
      });
  }

}
