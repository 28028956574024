import { Component, OnInit } from '@angular/core';
import { UtilitiesService } from 'src/app/services/utilities.service';
declare var $: any;

@Component({
  selector: 'app-portafolio',
  templateUrl: './portafolio.component.html',
  styleUrls: ['./portafolio.component.css']
})
export class PortafolioComponent implements OnInit {

  constructor(
    public utilitiesService: UtilitiesService,
  ) {
    this.scroll();
    utilitiesService.loading = true;
    setTimeout( function () {
      utilitiesService.loading = false;
    }, 1500);
  }

  ngOnInit() {
  }

  scroll() {
    $(function () {
          var dest = $('.ex-header').offset().top;
          $('html, body').animate({scrollTop: dest},600);

    });
  }

  getmodal(id:number){
    switch (id) {
      case 1:
        $('.servicewebinformative').click();
        break;
      case 2:
        $('.servicewebtienda').click();
        break;
      default:
        $('.servicewebconfig').click();
        break;
    }

  }




}
