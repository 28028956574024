import { Component, OnInit } from '@angular/core';
import { UtilitiesService } from 'src/app/services/utilities.service';
declare var $: any;



@Component({
  selector: 'app-policies',
  templateUrl: './policies.component.html',
  styleUrls: ['./policies.component.css']
})
export class PoliciesComponent implements OnInit {

  constructor(public utilitiesService:UtilitiesService){
    utilitiesService.loading = true;
    setTimeout(function(){
      utilitiesService.loading = false;
    }, 1500);
  }

  ngOnInit() {
    this.scroll();
  }

  scroll() {
    $(function () {
          var dest = $(".ex-header").offset().top;
          $("html, body").animate({scrollTop: dest},600);

    });
  }

}
